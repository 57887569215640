import React, { useContext, useState } from "react";
// import c from "classnames";
import { Donut } from "britecharts-react";
// import { Collapse, Button } from "reactstrap";
import Card from "../../components/Card/Card";
import { DataContext } from "../../context/DataContext";

const Tickets = () => {
  const data = useContext(DataContext);
  const { titoData } = data;
  //   const [isOpen, setIsOpen] = useState(false);
  //   const toggle = () => setIsOpen(!isOpen);

  const donutData = [
    {
      name: "Released",
      id: 1,
      quantity: titoData && titoData.quantity_used
    },
    {
      name: "Available",
      id: 2,
      quantity: titoData && titoData.quantity - titoData.quantity_used
    }
  ];

  return (
    <>
      {titoData && (
        <Card>
          <h3>Tickets</h3>
          <Donut
            data={donutData}
            height={200}
            width={235}
            externalRadius={70}
            internalRadius={40}
            colorSchema={["rgba(236, 88, 93, 0.5)", "rgba(236, 88, 93, 1)"]}
            isAnimated={false}
          />
          <div className="tickets-row">
            <div className="remaining-color tickets-color-box"></div>
            <div className="tickets-number">
              {titoData.quantity - titoData.quantity_used}
            </div>
            <div className="tickets-type">Available</div>
          </div>
          <div className="tickets-row">
            <div className="released-color tickets-color-box"></div>
            <div className="tickets-number">{titoData.quantity_used}</div>
            <div className="tickets-type">Released</div>
          </div>
          {/* <Button className="btn blue-color smaller" onClick={toggle}>
            See Tickets Released
          </Button>
          <Card className={c("grey-bg", isOpen ? "show" : "hide")}>
            <ul className="given-to">
              <Collapse isOpen={isOpen}>
                {titoData.releases.map((company, i) => (
                  <li key={i}>{company.title}</li>
                ))}
              </Collapse>
            </ul>
          </Card> */}
        </Card>
      )}
    </>
  );
};

export default Tickets;
