import React, { useContext, useState } from "react";
import { Button, Table, Modal, ModalHeader, ModalBody } from "reactstrap";
import Card from "../../components/Card/Card";
import { DataContext } from "../../context/DataContext";

const Deadlines = () => {
  const data = useContext(DataContext);
  const { deadlines } = data.wpData;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [details, setDetails] = useState(false);
  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  if (!deadlines) return null;

  const handleClickDetails = (eventName, details, airtable) => {
    setDetails({
      eventName,
      text: details,
      airtable
    });
    toggleModal();
  };

  console.log(
    "deadline",
    // data.wpData.deadlines,
    new Date().getDate(),
    new Date().getMonth()
    // new Date(data.wpData.deadlines[0].date).getMonth(),
    // new Date(data.wpData.deadlines[0].date).getDay()
  );

  return (
    <>
      <Card>
        <h3>Deadlines</h3>
        <Table hover responsive className="mb-0 mt-2">
          <ul className="deadlines">
            {deadlines.map((row, i) => {
              const eventTitle = row.title.rendered.replace(/&#038;/g, "&");
              const currentDate = new Date().getDate();
              const currentMonth = new Date().getMonth() + 1;
              const deadlineMonth = Number(row.acf.date[0] + row.acf.date[1]);
              const deadlineDate = Number(row.acf.date[2] + row.acf.date[3]);
              if (
                deadlineMonth > currentMonth ||
                (deadlineMonth === currentMonth && deadlineDate >= currentDate)
              ) {
                return (
                  <li className="deadlines-item" key={i}>
                    <div className="deadlines-items-date-and-title">
                      <h5 className="font-14 font-weight-normal deadlines-item-date">
                        {row.acf.date[2] +
                          row.acf.date[3] +
                          "/" +
                          row.acf.date[0] +
                          row.acf.date[1]}
                      </h5>
                      <h5 className="font-weight-bold deadlines-item-title">
                        {eventTitle}
                      </h5>
                    </div>
                    <div className="deadlines-item-links">
                      <div className="deadlines-item-airtable">
                        {row.acf.airtable_link && (
                          <a
                            className="btn smaller cta-color"
                            href={row.acf.airtable_link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Airtable
                          </a>
                        )}
                      </div>
                      <div className="deadlines-item-details">
                        {row.acf.details && (
                          <Button
                            className="btn smaller blue-color"
                            onClick={() =>
                              handleClickDetails(
                                eventTitle,
                                row.acf.details,
                                row.acf.airtable_link
                              )
                            }
                          >
                            Details
                          </Button>
                        )}
                      </div>
                    </div>
                  </li>
                );
              } else {
                return null;
              }
              // console.log("eventTitle", eventTitle);
            })}
          </ul>
        </Table>
      </Card>
      <Modal isOpen={modalIsOpen} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal}>{details.eventName}</ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: details.text }} />
          {details.airtable && (
            <a
              className="btn smaller cta-color"
              href={details.airtable}
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to Airtable
            </a>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default Deadlines;
