import React from "react";

const ShowroomIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      fill="currentColor"
      viewBox="0 0 92 92"
      enableBackground="new 0 0 92 92"
      xmlSpace="preserve"
    >
      <path
        id="XMLID_1719_"
        d="M92,13c0-2.2-1.8-4-4-4H4c-2.2,0-4,1.8-4,4v52c0,2.2,1.8,4,4,4h84c2.2,0,4-1.8,4-4V13z M84,61H8V17h76V61z
	 M69.5,79c0,2.2-1.8,4-4,4H26.5c-2.2,0-4-1.8-4-4s1.8-4,4-4h38.9C67.7,75,69.5,76.8,69.5,79z"
      />
    </svg>
  );
};

export default ShowroomIcon;
