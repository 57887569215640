import React from "react";
import "./loader-style.scss";

const Loader = ({ fullScreen }) => {
  return (
    <>
      {fullScreen ? (
        <div className="background">
          <div className="loader">
            <div className="circle wave"></div>
          </div>
        </div>
      ) : (
        <div className="loader">
          <div className="circle wave"></div>
        </div>
      )}
    </>
  );
};

export default Loader;
