import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import Card from "../components/Card/Card";
import Main from "../components/Main/Main";
import { isMobile } from "react-device-detect";

const MatchMaking = () => {
  const [gripInfo, setGripInfo] = useState();

  useEffect(() => {
    fetch(process.env.REACT_APP_WP_URL + "grip")
      .then(response => {
        if (!response.status === 200) {
          throw response.json();
        }
        return response.json();
      })
      .then(json => {
        const gripInfo = {
          title: json[0].title.rendered,
          description: json[0].acf.description,
          videos: json[0].acf.video
        };
        setGripInfo(gripInfo);
        return gripInfo;
      })
      .catch(error => {
        throw error;
      });
  }, []);

  return (
    <Main id="grip" pageTitle={"Match Making"}>
      {gripInfo && (
        <Row>
          <div className="grip-card-description">
            <h1>{gripInfo.title}</h1>
            <div
              dangerouslySetInnerHTML={{ __html: gripInfo.description }}
            ></div>
          </div>
          <ul className="grip-cards">
            {gripInfo.videos.map((video, id) => {
              return (
                <Card className="grip-card">
                  <div>
                    <h2>{video.title}</h2>
                    <p>{video.description}</p>
                  </div>
                  <iframe
                    title={"grip-video-" + id + 1}
                    width={isMobile ? "315" : "420"}
                    height={isMobile ? "178" : "236"}
                    src={`https://www.youtube.com/embed/${
                      video.youtube_embed.split("=")[1]
                    }`}
                    frameborder="0"
                    allow="accelerometer; autoplay; encryptedMedia; gyroscope; pictureInPicture"
                    allowfullscreen
                  ></iframe>
                </Card>
              );
            })}
          </ul>
        </Row>
      )}
    </Main>
  );
};

export default MatchMaking;
