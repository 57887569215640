import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataProvider } from "./context/DataContext";
import Nav from "./components/Nav/Nav";
import Loader from "./components/Loader/Loader";
import "./styles/App.scss";

function useGetCurrentUser(token, handleLogout) {
  const [userData, setUserData] = useState();
  const [titoData, setTitoData] = useState();

  useEffect(() => {
    const userURI = process.env.REACT_APP_WP_URL + "users/me";

    axios({
      method: "POST",
      url: userURI,
      headers: { Authorization: "Bearer " + token }
    })
      .then(function(response) {
        if (response.status === 200) {
          const data = response.data;
          const userURI =
            process.env.REACT_APP_WP_URL +
            "partners/" +
            data.acf.select_partner[0].ID;

          axios({
            method: "GET",
            url: userURI
          }).then(function(response) {
            if (response.status === 200) {
              const data = response.data;
              setUserData(data);
              const proxy = "https://cors-anywhere.herokuapp.com/";
              const url =
                process.env.REACT_APP_TITO_URL +
                "discount_codes/" +
                data.acf.tito_id;
              const token = "Token " + process.env.REACT_APP_TITO_KEY;

              fetch(proxy + url, {
                method: "GET",
                headers: {
                  Authorization: token,
                  Accept: "application/json",
                  "Content-Type": "application/json"
                }
              })
                .then(response => {
                  if (!response.status === 200) {
                    throw response.json();
                  }
                  return response.json();
                })
                .then(json => {
                  const { discount_code } = json;
                  setTitoData(discount_code);
                  return discount_code;
                })
                .catch(error => {
                  throw error;
                });
            }
          });
        }
      })
      .catch(function(error) {
        console.log("catching an error!", error);
        handleLogout();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { userData, titoData };
}

function useWpData() {
  const [wpData, setWpData] = useState({});

  useEffect(() => {
    const sources = ["dashboard_text", "deadlines", "addons"];
    let object = {};

    Promise.all(
      sources.map(source => {
        return fetch(process.env.REACT_APP_WP_URL + source)
          .then(response => {
            return response.json();
          })
          .then(data => {
            // Sort Deadlines Dates by Ascending Order
            if (source === "deadlines") {
              data = data.sort((a, b) => {
                return Number(a.acf.date) - Number(b.acf.date);
              });
            }
            object[source] = data;
            setWpData(object);
            return data;
          });
      })
    );
  }, []);
  return wpData;
}

function App({ token, setLogin }) {
  const handleLogout = () => {
    localStorage.removeItem("login");
    setLogin("");
  };

  const wpData = useWpData();
  const partnerData = useGetCurrentUser(token, handleLogout);

  return (
    <>
      {partnerData.userData ? (
        <DataProvider
          value={{
            wpData,
            partnerData: partnerData.userData,
            titoData: partnerData.titoData
          }}
        >
          <Nav handleLogout={handleLogout} />
        </DataProvider>
      ) : (
        <Loader fullScreen />
      )}
    </>
  );
}

export default App;
