import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Card from "../components/Card/Card";
import Main from "../components/Main/Main";
import { DataContext } from "../context/DataContext";

const Showroom = () => {
  const value = useContext(DataContext);
  const [showroomInfo, setShowroomInfo] = useState();

  const {
    showroom_booth_number,
    showroom_booth_measurements
  } = value.partnerData.acf;

  useEffect(() => {
    fetch(process.env.REACT_APP_WP_URL + "showroom")
      .then(response => {
        if (!response.status === 200) {
          throw response.json();
        }
        return response.json();
      })
      .then(json => {
        setShowroomInfo(json[0].acf);
        return json[0].acf;
      })
      .catch(error => {
        throw error;
      });
  }, []);

  return (
    <Main id="showroom" pageTitle={"Showroom"}>
      {value &&
        (value.partnerData.acf.showroom_booth_number ||
          value.partnerData.acf.showroom_booth_measurements) && (
          <Row>
            <Col xl={12}>
              <Card>
                <Row className="booth-card">
                  {showroom_booth_number && (
                    <div className="booth-info">
                      Your booth number:
                      <br />
                      <strong>{showroom_booth_number}</strong>
                    </div>
                  )}
                  {showroom_booth_measurements && (
                    <div className="booth-info">
                      Your booth measurements:
                      <br />
                      <strong>{showroom_booth_measurements}</strong>
                    </div>
                  )}
                </Row>
              </Card>
            </Col>
          </Row>
        )}
      <Row>
        <Col xl={12}>
          {showroomInfo && (
            <Card>
              <div dangerouslySetInnerHTML={{ __html: showroomInfo.text }} />
            </Card>
          )}
        </Col>
      </Row>
    </Main>
  );
};

export default Showroom;
