import React, { useContext } from "react";
import { Col, Row } from "reactstrap";
import Main from "../components/Main/Main";
import Addons from "../modules/Addons/Addons";
import Contract from "../modules/Contract/Contract";
import Deadlines from "../modules/Deadlines/Deadlines";
import Tickets from "../modules/Tickets/Tickets";
import { DataContext } from "../context/DataContext";

const Dashboard = () => {
  const data = useContext(DataContext);
  const { partnerData } = data;

  console.log("dashboard data");

  const { title, acf } = data.wpData.dashboard_text[0];

  return (
    <Main
      id="dashboard"
      pageTitle="Dashboard"
      partner={
        partnerData && partnerData.title.rendered.replace(/&#038;/g, "&")
      }
    >
      <Row>
        <Col xl={9}>
          <h2>{title.rendered}</h2>
          <p dangerouslySetInnerHTML={{ __html: acf.welcome_description }} />
          <br />
          <Deadlines />
          <Addons />
        </Col>
        <Col xl={3}>
          <Contract />
          <Tickets />
        </Col>
      </Row>
    </Main>
  );
};

export default Dashboard;
