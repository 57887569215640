import React, { useContext } from "react";
import { Col, Row } from "reactstrap";
import { DataContext } from "../../context/DataContext";

const Main = ({ id, pageTitle, children }) => {
  const data = useContext(DataContext);
  const partner = data.partnerData.title.rendered;
  console.log("Main data", data.partnerData.acf.company_logo);
  return (
    <main id={id}>
      <Row>
        <Col xl={8} className="main-header">
          <div className="main-titles">
            <span>{partner}</span>
            <h1>{pageTitle}</h1>
          </div>
          {data.partnerData.acf.company_logo && (
            <div className="partner-logo">
              <img
                src={data.partnerData.acf.company_logo.url}
                alt="company logo"
              />
            </div>
          )}
        </Col>
      </Row>
      <hr></hr>
      {children}
    </main>
  );
};

export default Main;
