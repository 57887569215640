import React, { useState } from "react";
import axios from "axios";
import { TextControl, Button } from "@wordpress/components";

const Login = ({ url, setLogin }) => {
  const [username, setUsername] = useState("unity");
  const [password, setPassword] = useState("manbearpig123");

  const handleSubmit = e => {
    console.log("user pw", username, password);
    if (e) e.preventDefault();
    console.log("user pw", username, password);

    // Trying this
    // const proxy = "https://cors-anywhere.herokuapp.com/";
    // axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    //

    axios
      .post(url + "wp-json/jwt-auth/v1/token/", {
        username,
        password
      })
      .then(function(response) {
        console.log("login response: ", response);
        if (response.status === 200) {
          console.log("=== 200: token ", response.data.token);
          const data = response.data;
          localStorage.setItem("login", data.token);
          setLogin(data.token);
        }
      })
      .catch(function(error) {
        console.log("error", error);
        function strip_html_tags(str) {
          if (str === null || str === "") return false;
          else str = str.toString();
          return str.replace(/<[^>]*>/g, "");
        }
        // alert(strip_html_tags(error.response.data.message));
        // console.log(strip_html_tags(error.response.data.message));
      });
  };

  const handleCheckForEnter = e => {
    if (e.which === 13 || e.key === "Enter" || e.keyCode === 13) handleSubmit();
  };

  return (
    <div className="login-page">
      <form className="login" method="post">
        <h1>TechBBQ Partnership Portal</h1>
        <TextControl
          placeholder="Username"
          value={username}
          onChange={value => setUsername(value)}
        />
        <TextControl
          placeholder="Password"
          type="password"
          onChange={value => setPassword(value)}
          onKeyDown={e => handleCheckForEnter(e)}
        />
        <Button onClick={e => handleSubmit(e)}>Login</Button>
      </form>
    </div>
  );
};

export default Login;
