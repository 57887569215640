import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Card from "../components/Card/Card";
import Main from "../components/Main/Main";

const Contact = () => {
  const [contacts, setContacts] = useState();

  useEffect(() => {
    fetch(process.env.REACT_APP_WP_URL + "contacts")
      .then(response => {
        if (!response.status === 200) {
          throw response.json();
        }
        return response.json();
      })
      .then(json => {
        setContacts(
          json.sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
          )
        );
        return json;
      })
      .catch(error => {
        throw error;
      });
  }, []);

  return (
    <Main id="contact" pageTitle={"Contact"}>
      <Row>
        {contacts &&
          contacts.map((person, i) => {
            return (
              <Col xl={3} key={i}>
                <Card>
                  <h1>{person.title.rendered}</h1>
                  <p>{person.acf.role}</p>
                  <a href={`mailto:${person.acf.email}`}>{person.acf.email}</a>
                </Card>
              </Col>
            );
          })}
      </Row>
    </Main>
  );
};

export default Contact;
