import React, { useState } from "react";
import { Switch, Route, NavLink, MemoryRouter } from "react-router-dom";
import "./nav-style.scss";
import HomeIcon from "../Icons/HomeIcon";
import MapIcon from "../Icons/MapIcon";
import GripIcon from "../Icons/GripIcon";
import ContactIcon from "../Icons/ContactIcon";
import Dashboard from "../../pages/Dashboard";
import Showroom from "../../pages/Showroom";
import Grip from "../../pages/MatchMaking";
import Contact from "../../pages/Contact";

const links = [
  {
    name: "Home",
    path: "/",
    icon: HomeIcon
  },
  {
    name: "Showroom",
    path: "/showroom",
    icon: MapIcon
  },
  {
    name: "Match Making",
    path: "/grip",
    icon: GripIcon
  },
  {
    name: "Contact",
    path: "/contact",
    icon: ContactIcon
  }
];

const Nav = ({ handleLogout }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  console.log("NAV?", menuIsOpen);
  return (
    <MemoryRouter>
      <button
        className="hamburger-menu"
        onClick={() => setMenuIsOpen(!menuIsOpen)}
      >
        {menuIsOpen ? "Close" : "Menu"}
      </button>
      <div
        className={menuIsOpen ? "overlay overlay-show" : "overlay"}
        onClick={() => setMenuIsOpen(!menuIsOpen)}
      ></div>
      <nav className={menuIsOpen && "nav-show"}>
        <h1>TechBBQ</h1>
        <h2>Partner Portal</h2>
        <ul>
          {links.map((link, i) => {
            const Icon = link.icon;
            return (
              <li key={i}>
                <NavLink to={link.path} exact={link.path === "/" && true}>
                  <Icon />
                  {link.name}
                </NavLink>
              </li>
            );
          })}
        </ul>
        <button className="log-out" onClick={handleLogout}>
          Log out
        </button>
      </nav>
      <Switch>
        <Route path="/" exact>
          <Dashboard />
        </Route>
        <Route path="/showroom">
          <Showroom />
        </Route>
        <Route path="/grip">
          <Grip />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
      </Switch>
    </MemoryRouter>
  );
};

export default Nav;
