import React, { useContext } from "react";
import Card from "../../components/Card/Card";
import { sanitizeText } from "../../utils/utils";
import { DataContext } from "../../context/DataContext";
import "./addons-style.scss";

const Addons = () => {
  const data = useContext(DataContext);
  const { addons } = data.wpData;
  const { partnerData } = data;

  if (!addons) return null;

  return (
    <Card className="addons">
      <h3>Addons</h3>
      <hr />
      <ul className="addons-cards">
        {addons.map((addon, i) => {
          const {
            subtitle,
            bullet_points,
            danish_price,
            euro_price,
            display_addon
          } = addon.acf;
          if (display_addon === "No") return null;
          const emailSubject = `mailto: snj@techbbq.org?subject=${sanitizeText(
            partnerData.title.rendered
          )} are interested in the ${sanitizeText(
            addon.title.rendered
          )} upgrade`;

          let purchased = false;

          // If the partner has already purchased it - do not show
          if (
            partnerData.acf.addons_purchased &&
            partnerData.acf.addons_purchased.some(item => item.ID === addon.id)
          )
            purchased = true;

          return (
            <li
              className="addon-card"
              style={{ opacity: purchased ? 0.5 : 1 }}
              key={i}
            >
              <div className="addon-card-header">
                <h4>{sanitizeText(addon.title.rendered)}</h4>
                <span>{subtitle}</span>
              </div>
              <div className="addon-card-content">
                <ul className="addon-bullet-points">
                  {bullet_points.map((point, i) => {
                    return (
                      <li className="addon-bullet-point" key={i}>
                        + {point.bullet_point}
                      </li>
                    );
                  })}
                </ul>
                <div>
                  <h5>
                    {danish_price} DKK / {euro_price} €
                  </h5>
                  {!purchased ? (
                    <a
                      href={emailSubject}
                      className="btn cta-color-filled"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Upgrade
                    </a>
                  ) : (
                    <span>You have purchased this addon</span>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </Card>
  );
};

export default Addons;
