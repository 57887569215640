import React, { useContext } from "react";
import Card from "../../components/Card/Card";
import { DataContext } from "../../context/DataContext";

const Contract = () => {
  const data = useContext(DataContext);
  const { partnerData } = data;

  if (!partnerData.acf.contract) return null;

  return (
    <Card>
      <h3>Contract</h3>
      <p>
        {partnerData.title.rendered.replace(/&#038;/g, "&")} x TechBBQ
        Partnership Agreement
      </p>
      <a
        href={partnerData.acf.contract}
        className="btn cta-color-filled"
        target="_blank"
        rel="noopener noreferrer"
        type="download"
      >
        Download
      </a>
    </Card>
  );
};

export default Contract;
