import React from "react";

const HomeIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 92 92"
      enableBackground="new 0 0 92 92"
      xmlSpace="preserve"
      fill="currentColor"
    >
      <path
        id="XMLID_100_"
        d="M88,49c-1,0-2-0.4-2.8-1.1L46,9.6L6.8,47.9c-1.6,1.5-4.1,1.5-5.7-0.1c-1.5-1.6-1.5-4.1,0.1-5.7l42-41
	c1.6-1.5,4-1.5,5.6,0l42,41c1.6,1.5,1.6,4.1,0.1,5.7C90.1,48.6,89,49,88,49z M79.2,88V48.9c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4V84
	H58.7V62.6c0-2.9-2.4-5.3-5.3-5.3H38.6c-2.9,0-5.3,2.4-5.3,5.3V84H20.8V48.9c0-2.2-1.8-4-4-4s-4,1.8-4,4V88c0,2.2,1.8,4,4,4h20.5
	c2.2,0,4-1.8,4-4V65.3h9.5V88c0,2.2,1.8,4,4,4h20.5C77.5,92,79.2,90.2,79.2,88z"
      />
    </svg>
  );
};

export default HomeIcon;
