import React, { useEffect, useState } from "react";
import Login from "./pages/Login";
import App from "./App";
import "./styles/App.scss";

function Auth() {
  const [login, setLogin] = useState("");
  // const siteURL = "http://localhost:8888/techbbq-partner-portal";
  const siteURL = "https://portal.techbbq.dk/";

  useEffect(() => {
    const localLogin = localStorage.getItem("login");
    // If we have logged in, set it.
    if (localLogin) {
      setLogin(localLogin);
    }
  }, [login]);

  return (
    <>
      {login ? (
        <App url={siteURL} setLogin={setLogin} token={login} />
      ) : (
        <Login url={siteURL} setLogin={setLogin} />
      )}
    </>
  );
}

export default Auth;
