import React from "react";

const MapIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 92 92"
      enableBackground="new 0 0 92 92"
      xmlSpace="preserve"
      fill="currentColor"
    >
      <path
        id="XMLID_1038_"
        d="M90.2,14.8L69.1,4.9c-0.1,0-0.1,0-0.1-0.1c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1
	c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.1,0-0.2,0.1l-19.7,9.2L26.8,4.9
	c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0
	c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.1,0-0.2,0.1L2.3,14.8C1,15.4,0,16.6,0,18v65c0,1.2,0.9,2.3,1.9,3
	c0.6,0.4,1.4,0.6,2,0.6c0.5,0,1.1-0.1,1.6-0.3L25.2,77L45,86.2c0,0,0,0,0,0c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0,0.1,0,0.2,0
	c0.2,0,0.4,0.1,0.7,0.1c0.2,0,0.5,0,0.7-0.1c0.1,0,0.1,0,0.2,0c0.2-0.1,0.4-0.1,0.6-0.2c0,0,0,0,0,0L67.7,77l19.7,9.2
	c0.5,0.2,1,0.3,1.5,0.3c0.7,0,1.1-0.2,1.6-0.6c1-0.7,1.4-1.8,1.4-3V18C92,16.6,91.5,15.4,90.2,14.8z M42,77.5l-13-6.6V13.7l13,6.6
	V77.5z M7,20.3l14-6.6v57.2L7,77.5V20.3z M50,20.3l14-6.6v57.2l-14,6.6V20.3z M85,77.5l-14-6.6V13.7l14,6.6V77.5z"
      />
    </svg>
  );
};

export default MapIcon;
